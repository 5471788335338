import * as Sentry from '@sentry/vue'
import auth from './auth'
import SignInApi from '../api/signIn'

async function signInByForm(app, data) {
  let r = false
  try {
    // const api = await SignInApi.signInByForm(userEmail, userPassword)
    // if (api.data.result === true) {
    //   const res = api.data.data
    //   const tokenItem = res.authorization
    //   r = true
    //   await auth.setAccessToken(app, tokenItem)
    //   app.$store.dispatch('Account/setUser', res.user)
    //   app.$store.dispatch('Account/setUserExtra', res.user_extra)
    // }
    await auth.setAccessToken(app, {
      access_token: 'abcd',
      refresh_token: 'abcd',
      expire_in_minutes: 60000
    })
    app.$store.dispatch('Account/setUser', {
      id: data?.id,
      email: data?.email,
      name: data?.username,
      role: data?.role
    })
    app.$store.dispatch('Account/setUserExtra', {
      id: 1,
      user_id: data?.id,
      avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
      nickname: data?.username,
      bio:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      birthday: '1990-01-01'
    })
    r = true
  } catch (e) {
    Sentry.captureMessage('Sign-In Error')
    Sentry.captureException(e)
    r = false
  }
  return r
}

export default {
  signInByForm
}
