<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy">
    <v-text-field ref="userEmail" v-model="userEmail" :autofocus="userEmailFocus" label="User ID" required />
    <v-text-field
      ref="userPassword"
      v-model="userPassword"
      label="Password"
      :type="showPasswordField ? 'text' : 'password'"
      :append-icon="showPasswordField ? 'mdi-eye' : 'mdi-eye-off'"
      required
      @click:append="showPasswordField = !showPasswordField"
      @keyup.enter="doLogin"
    />
    <v-btn small block large :loading="isLoading" @click="doLogin">
      Sign In
    </v-btn>
  </v-form>
</template>

<script>
import Signin from '../models/signIn'
import Config from '../../../config'
import axios from 'axios'

export default {
  data: () => ({
    isLoading: false,
    showPasswordField: false,
    valid: false,
    name: '',
    userEmail: '',
    userEmailFocus: true,
    userPassword: '',
    emailRules: [v => !!v || 'User Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    select: null,
    checkbox: false,
    lazy: true
  }),

  mounted() {
    this.$refs.form.reset()
    this.$refs.userEmail.$el.focus()
  },

  methods: {
    async doLogin() {
      if (this.$refs.form.validate() === false) {
        this.$nextTick(() => this.$refs.userEmail.$el.focus())
        return
      }
      this.isLoading = true
      const result = await axios
        .post(`${Config.Api.Service2.url}/admin/login`, {
          username: this.userEmail,
          password: this.userPassword
        })
        .catch(err => err)

      this.isLoading = false

      if (result.status === 201) {
        await Signin.signInByForm(this, result.data)
        this.$router.push('/dashboard')
      } else {
        this.isLoading = false
        window.alert('이메일또는 비밀번호를 확인해주세요.')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
